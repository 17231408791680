import firebase from ".././helpers/firebase";

export const SignIn = (username, password) => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_AUTH_MESSAGE",
      loading: false,
    });

    const responseOne = await fetch(
      `${process.env.REACT_APP_API_END_POINT}/web/login.php`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          device_token: localStorage.getItem("dToken"),
          username: username,
          password: password,
        }),
      }
    );
    const response = await responseOne.json();

    localStorage.setItem("recruiter_id", response?.data?.id);
    if (response.data.is_verified == 0) {
      localStorage.setItem("auth_id1", response.data.auth_id);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("eco_complaince", response.data.eco_complaince);
      localStorage.setItem("isFirstLogin", response.data.isFirstLogin);
      localStorage.setItem("is_verified", response.data.is_verified);

      return response;
    }

    if (response.data.blocked == 1) {
      alert("Your Account is Disabled");
      // dispatch({
      //   loading: true,
      // });
      return;
    } else if (
      Object.keys(response.data).length === 0 &&
      response.status !== 200
    ) {
      console.log("errrrorr");
      // dispatch({
      //   loading: true,
      // });
      return response;
    } else if (
      Object.keys(response.data).length > 0 &&
      response.status === 200
    ) {
      const auth_id = response.data.auth_id;
      const empId = response.data.id;
      const first_name = response.data.company_name.split(" ")[0];
      const eco_complaince = response.data.eco_complaince;
      const isFirstLogin = response.data.first_time_login;
      dispatch({
        type: "SIGN_IN",
        authError: null,
        auth_id,
        employee_id: empId,
        authMessage: "Signing you in...",
        loading: true,
      });
      localStorage.setItem("auth_id1", auth_id);
      localStorage.setItem("name", first_name);
      localStorage.setItem("eco_complaince", eco_complaince);
      localStorage.setItem("isFirstLogin", isFirstLogin);
      localStorage.setItem("is_verified", response.data.is_verified);

      // window.location = "/dashboard";
      return response;
    }
  };
};
export const signOut = (props) => {
  localStorage.removeItem("auth_id1");
  localStorage.removeItem("name");
  localStorage.removeItem("dToken");
  window.location = "/";
  return {
    type: "SIGN_OUT",
  };
};

export const facebookLogin = () => {
  return (dispatch) => {
    var provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        console.log(result);
      })
      .catch(function (error) {
        console.log("ffff", error);
      });
  };
};

export const googleLogin = () => {
  return (dispatch) => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        var user = result.user;
        var first = user.displayName.split(" ")[0];
        var last = user.displayName.split(" ")[1];
        var data = {
          type: 1,
          email: user.email,
          first_name: first,
          last_name: last,
          num: "000000",
          token: user.l,
          device_token: "qwer123rfadf45",
        };
        console.log("gggggggg", data);

        fetch(`${process.env.REACT_APP_API_END_POINT}/user/social_login.php`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            type: 1,
            email: user.email,
            first_name: first,
            last_name: last,
            num: "000000",
            token: user.l,
            device_token: localStorage.getItem("dToken"),
          }),
        })
          .then((res) => {
            console.log("res aqib", res);
            if (res.status !== 200) {
              alert("Incorrect Email Or Password...");
            }
            return res.json();
          })
          .then((response) => {
            console.log("data", response);
            const auth_id = response.data.auth_id;

            if (auth_id !== "" && auth_id !== null && auth_id !== undefined) {
              window.location = "/dashboard";
            } else {
              alert("email or password is incorrect...");
            }
            localStorage.setItem("auth_id1", auth_id);
            // window.location.reload(false)
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log("ffff", error);
      });
  };
};
