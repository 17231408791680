import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { SignIn } from "../../actions/authAction";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { Link, useHistory } from "react-router-dom";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { Helmet } from "react-helmet";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import Premium from "../PremiumPackages/Premium";
import { createOtp } from "../../actions/otpAction";

function Login(props) {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginInProgress, setLoginInProgress] = useState(false);

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  // useEffect(async () => {
  //   await navigator.geolocation.getCurrentPosition((position) => {
  //     console.log("Latitude is :", position.coords.latitude);
  //     console.log("Longitude is :", position.coords.longitude);
  //     fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GMAP_API}`,
  //       {
  //         method: "GET",
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((response) => {
  //         console.log(response);
  //         localStorage.setItem(
  //           "userCurrentLocation",
  //           response.results.find((x) => x.types[0] == "country")
  //             .formatted_address
  //         );
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //       });
  //   });
  // }, []);
  const AddLogin = async () => {
    setLoginInProgress(true);
    const reg =
      /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.([a-zA-Z]+).([a-zA-Z]+)$/;
    if (username == "") {
      setSnackMessage("Username or Email required");
      setSnackSeverity("error");
      setSnackOpen(true);
      setLoginInProgress(false);
    } else if (reg.test(username) === false) {
      setSnackMessage("Email is invalid");
      setSnackSeverity("error");
      setSnackOpen(true);
      setLoginInProgress(false);
    } else if (password == "") {
      setSnackMessage("Password required");
      setSnackSeverity("error");
      setSnackOpen(true);
      setLoginInProgress(false);
    } else if (password.length < 6) {
      setSnackMessage("Password length must be greater than 6");
      setSnackSeverity("error");
      setSnackOpen(true);
      setLoginInProgress(false);
    } else {
      const result = await props.SignIn(username, password);
      console.log(result);
      if (result && result.data && result.data.is_verified == "0") {
        window.location = "/otp";
      } else if (result && result.data && result.data.is_verified == 1) {
        setSnackMessage("Logged in Successfully!");
        setSnackSeverity("success");
        setSnackOpen(true);
        window.location = "/dashboard";
      } else if (result && result.status === 404) {
        setSnackMessage("Wrong Email or Password!");
        setSnackSeverity("error");
        setSnackOpen(true);
      } else console.log(result);
      // setSnackMessage('Logging In');
      // setSnackSeverity('success');
      // setSnackOpen(true);
      setLoginInProgress(false);
    }
  };

  if (
    localStorage.getItem("auth_id1") !== null &&
    localStorage.getItem("auth_id1") !== undefined &&
    localStorage.getItem("auth_id1") !== "" &&
    localStorage.getItem("is_verified") === "1"
  ) {
    window.location = "/dashboard";

    // history.push("/dashboard");
  }

  return (
    <>
      <Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <meta name="description" content="Welcome to cvvlogs" />
      </Helmet>
      {/* <Navbar /> */}
      <div className="container-fluid back-img-login">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-5"></div>
            <div className="col-lg-4 col-md-7 main-login-form shadow p-5">
              {/* Login Head Starts */}
              <div className="row">
                <div className="col-md-12">
                  <h1 className="recr-login-head">Recruiter Login</h1>
                </div>
              </div>
              {/* Login Head Ends */}

              <label className="p-0 m-0 recr-login-label mt-1">
                Username or Email
              </label>
              <input
                type="email"
                required
                className="form-control recr-login-input"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Type your username or email"
              />
              <label className="p-0 m-0 recr-login-label mt-2">Password</label>
              <input
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    console.log("enterrrr");
                    AddLogin();
                  }
                }}
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Type your password"
                className="form-control recr-login-input"
              />
              <div className="row">
                <div className="col-md-12">
                  <Link to="/forgetpassword">
                    <p className="forget-pass-login">Forget Password?</p>
                  </Link>
                </div>
              </div>
              {/* Button Starts */}
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <input
                    type="submit"
                    className="btn btn-primary w-100 login-recr-btn form-control"
                    onClick={() => AddLogin()}
                    value="Log In"
                    disabled={loginInProgress}
                  />
                </div>
                <div className="col-md-1"></div>
              </div>
              {/* Button Ennds */}
              {/* Hr starts */}
              <div className="row">
                <div className="col-md-12 ">
                  <hr className="horizon-rule-reg" />
                </div>
              </div>
              {/* Hr Emnds */}
              {/* Register Here Starts*/}
              <div className="row mt-3">
                <div className="col-md-12">
                  <p className="dont-account-login">
                    Don't have an Account?{" "}
                    <Link to="/register">
                      <span className="reg-here-login">Register here</span>
                    </Link>
                  </p>
                </div>
              </div>
              {/* Register Here Ends*/}
            </div>

            <div className="col-12 about-us shadow main-login-form mt-2 p-5">
              <h1 className="about-title-login">About Us</h1>
              <p>
                Welcome to cvvlogs, a new era of recruitment, Job search and
                recruitment is often a time consuming and stressful experience,
                to solve this issue we have developed the world’s first
                dedicated video resume platform.
              </p>
              <p>
                In a rapidly changing environment, standing out in the crowd is
                difficult, and so we created this unique portal.
              </p>
              <p>
                cvvlogs is the first and only online dedicated video resume
                platform where job seekers can upload a video resume to showcase
                their personalities and communication skills to get noticed by
                recruiters and get hired to pursue their dream careers.
              </p>
              <p>
                This unique platform allows the job seekers to show their
                personality and strengths on camera rather than being judged by
                a written resume.
              </p>
              <p>
                Our platform also makes it easier for recruiters to browse
                through our extensive video library of candidates to save time
                and find quality applicants by viewing, shortlisting and hiring
                them with ease and efficiency.
              </p>
              <p>
                So if you are to ready to experience this new and efficient form
                of recruitment, then please reach out to one of our team members
                to find out how we can assist and support you.
              </p>
              <h1 className="about-title-login">Our Mission</h1>
              <p className="mb-5">
                Our mission is to change the landscape of conventional
                recruitment with this unique, dynamic and reliable video
                platform that allows employers and candidates to connect
                globally with efficiency and transparency.
              </p>
            </div>
            <div className="col-12 about-us shadow p-2 main-login-form mb-2">
              <Premium componentMode={true} />
            </div>
          </div>
        </div>
        {/* {props.auth.loading == false ? <FullPageLoader /> : null} */}
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = (dispatch) => ({
  SignIn: (username, password) => dispatch(SignIn(username, password)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
