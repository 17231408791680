import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CreateAJob.css";
import Nav2 from "../../Components/Nav2/Nav2";
import { connect } from "react-redux";
import { getCreateJobData, createJob } from "../../actions/getcreatejobAction";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { countries } from "country-data";
import { Editor } from "primereact/editor";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { editJob, getEditJobData } from "../../actions/editJobAction";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Select from "react-select";
import Modal from "react-modal";
import CreatableSelect from "react-select/creatable";

function CreateAJobs(props) {
  const [countryName, setCountryName] = useState("");
  const components = {
    DropdownIndicator: null,
  };

  const [inputValue, setInputValue] = React.useState("");
  const [inputValueNew, setInputValueNew] = React.useState("");

  const [valueNew, setValueNew] = React.useState([]);

  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        const skillCheck = formData.skills.find(
          (el) => el.name.toLocaleLowerCase() === inputValue.toLocaleLowerCase()
        );
        if (skillCheck) {
          setInputValue("");
          setSnackSeverity("error");
          setSnackMessage(
            "Skill already exists in list, Please choose from list!"
          );
          setSnackOpen(true);
        } else if (inputValue.length > 15) {
          setInputValue("");
          setSnackSeverity("error");
          setSnackMessage("A skill can not exceed 15 characters!");
          setSnackOpen(true);
        } else {
          setValueNew((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
        }
        event.preventDefault();
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      width: "124%",
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : null,
      "&:hover": {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : null,
      color: state.isSelected ? "white" : null,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "124%",
    }),
  };
  const customStylesPopUp = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    let userAdded = valueNew.map((el) => el.label);
    setValue("user_added", userAdded);
    setIsOpen(false);
  }

  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [skillsRequired, setSkillsRequired] = useState(false);

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const history = useHistory();

  useEffect(async () => {
    const newData = await fetch(
      `${process.env.REACT_APP_API_END_POINT}/web/company_info.php`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          auth_id: localStorage.getItem("auth_id1"),
        },
      }
    );

    const response = await newData.json();
    setCountryName(response.data.country);
    if (response.data.profile_percentage === "incomplete") {
      history.push("/dashboard");
    }
  }, []);

  let { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setLoader(true);
    if (!data.skills && !data.user_added) {
      setSnackMessage("Please add atleast 1 skill to create a Job!");
      setSnackSeverity("error");
      return setSnackOpen(true);
    } else if (id) {
      await props.editJob(localStorage.getItem("auth_id1"), id, data);
    } else {
      const response = await props.createJob(
        localStorage.getItem("auth_id1"),
        data
      );
      if (response && response.error) {
        setSnackMessage(response.error);
        setSnackSeverity("error");
        setSnackOpen(true);
        setLoader(false);
      }
    }
  };
  const watchAllFields = watch();

  console.log(watchAllFields);

  const countryyyy =
    formData &&
    formData.countries &&
    formData.countries.find((el) => el.country_name === countryName);

  useEffect(async () => {
    setLoader(true);

    await props
      .getCreateJobData(localStorage.getItem("auth_id1"))
      .then(async (res) => {
        setFormData((prev) => (prev = res));
        if (id) {
          await props
            .getEditJobData(localStorage.getItem("auth_id1"), id)
            .then(async (edit_res) => {
              let { stored_values } = edit_res;
              let resetData = {
                job_title: stored_values.job_title,
                vacancies: stored_values.vacancy,
                job_description: stored_values.job_desc,
                gender: stored_values.gender_obj.id,
                skill_by_industry: stored_values.industry.cat_id,
                functional_area: stored_values.functional_area.id,
                required_work_level: stored_values.work_level.id,
                curr_type: stored_values.currency,
                salary_type: stored_values.salary_type.id,
                country_id: stored_values.city_by_country.country_id,
                state_id: stored_values.city_by_country.state_id,
                work_location_city: stored_values.city_by_country.city_id,

                salary_min:
                  Number(stored_values.minimum_salary.replace(/,/g, "")) || "",
                salary_max:
                  Number(stored_values.maximum_salary.replace(/,/g, "")) || "",
                skills: stored_values.skill,
                experience_level: stored_values.exp.id,
              };
              await getAllStatesData(resetData);
              await reset(resetData);
            });
        } else {
          await getAllStatesData({});
        }
      });
    await setLoader(false);
  }, []);
  const getAllStatesData = async (storeData) => {
    await axios
      .get(`${process.env.REACT_APP_API_END_POINT}/web/get_country_list.php`)
      .then(async (res) => {
        await setFormData((prev) => ({
          ...prev,
          countries: res.data.data.country,
        }));
        if (id) {
          await axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/web/fetch_state_list.php`,
              {
                country_id: storeData?.country_id,
              }
            )
            .then(async (res) => {
              await setFormData((prev) => ({
                ...prev,
                states: res.data.data.state_list,
              }));
              await axios
                .post(
                  `${process.env.REACT_APP_API_END_POINT}/web/fetch_city_list.php`,
                  {
                    country_id: storeData?.country_id,
                    state_id: storeData?.state_id,
                  }
                )
                .then(async (res) => {
                  await setFormData((prev) => ({
                    ...prev,
                    cities: res.data.data.city_list,
                  }));
                });
            });
        }
      });
  };

  useEffect(() => {
    const handleCountry = async () => {
      setValue("country_id", countryyyy && countryyyy.country_id);
      await axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/web/fetch_state_list.php`,
          {
            country_id: countryyyy && countryyyy.country_id,
          }
        )
        .then((res) => {
          setFormData((prev) => ({
            ...prev,
            states: res.data.data.state_list,
          }));
        });
    };
    handleCountry();
  }, [countryyyy]);

  const handleState = (e) => {
    setValue("country_id", countryyyy && countryyyy.country_id);

    if (e.target.value) {
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/web/fetch_city_list.php`,
          {
            country_id: countryyyy.country_id,
            state_id: e.target.value,
          }
        )
        .then((res) => {
          setFormData((prev) => ({
            ...prev,
            cities: res.data.data.city_list,
          }));
        });
    }
  };

  if (loader) {
    return <FullPageLoader />;
  }
  return (
    <>
      <Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <Nav2 />
      <div className="container">
        <h1 className="create-job-recr-head mt-5">
          {id ? "Update a Job" : "Create a Job"}
        </h1>

        {/*form*/}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-md-12 shadow p-5 mt-2 create-job-main-recr">
              <div className="row">
                <div className="col-md-6">
                  {/* job title */}
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <label className=" salary-range-recr">Job Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter job title (candidate will search by this title)"
                        {...register("job_title", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  {/* country */}
                  <div className="row">
                    <div className="col-md-12">
                      <label className=" salary-range-recr">Country</label>
                      <select
                        className="form-control per-det-input"
                        aria-label="Default select example"
                        {...register("country_id", {
                          required: true,
                          // onChange: (e) => handleCountry(e),
                        })}
                        defaultValue={
                          countryyyy && countryyyy.country_id
                            ? countryyyy.country_id
                            : ""
                        }
                        disabled
                      >
                        {countryyyy ? (
                          <option
                            value={countryyyy.country_id}
                            key={countryyyy.country_id}
                          >
                            {countryyyy && countryyyy.country_name
                              ? countryyyy.country_name
                              : ""}
                          </option>
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className=" salary-range-recr">
                        State / Province
                      </label>
                      <select
                        className="form-control per-det-input"
                        aria-label="Default select example"
                        {...register("state_id", {
                          required: true,
                          onChange: (e) => handleState(e),
                        })}
                      >
                        <option value="">Select State / Province</option>
                        {formData?.states?.map((x, i) => (
                          <option value={x.state_id} key={i}>
                            {x.state_name !== null && x.state_name !== undefined
                              ? x.state_name
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.state_id?.type === "required" && (
                        <p className="error-message">State is required</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className=" salary-range-recr">City</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        {...register("work_location_city", {
                          required: true,
                        })}
                      >
                        <option value="">Select City</option>
                        {formData?.cities?.map((x, i) => (
                          <option value={x.city_id} key={i}>
                            {x.city_name !== null && x.city_name !== undefined
                              ? x.city_name
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.work_location_city?.type === "required" && (
                        <p className="error-message">City is required</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">Job Type</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        {...register("required_work_level", {
                          required: true,
                        })}
                      >
                        <option value="">Select here</option>
                        {formData?.work_level?.map((x, i) => (
                          <option value={x.id} key={i}>
                            {x.work_level !== null && x.work_level !== undefined
                              ? x.work_level
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.required_work_level?.type === "required" && (
                        <p className="error-message">Job Type is required</p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">Industry</label>
                      <select
                        className="form-control"
                        {...register("skill_by_industry", {
                          required: true,
                        })}
                      >
                        <option value="">Select industry</option>
                        {formData?.skill_by_industry?.map((x, i) => (
                          <option value={x.cat_id} key={i}>
                            {x.cat_name !== null && x.cat_name !== undefined
                              ? x.cat_name
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.skill_by_industry?.type === "required" && (
                        <p className="error-message">Industry is required</p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">Skills</label>
                      <Select
                        options={formData?.skills || []}
                        value={watchAllFields?.skills || []}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => {
                          if (option && option.name) {
                            return option.name || "";
                          }
                        }}
                        isMulti
                        styles={customStyles}
                        onChange={(selected) => {
                          setValue("skills", selected);
                        }}
                        required
                        inputValue={inputValueNew}
                        onInputChange={(inputValueNew) => {
                          setInputValueNew(inputValueNew);
                        }}
                        filterOption={(option, inputValueNew) => {
                          if (inputValueNew.length < 3) {
                            // don't show options until input value is at least 3 characters
                            return false;
                          }
                          return option.label
                            .toLowerCase()
                            .includes(inputValueNew.toLowerCase());
                        }}
                        noOptionsMessage={() => {
                          if (inputValueNew.length < 3) {
                            // don't show "no options" message until input value is at least 3 characters
                            return null;
                          }
                          return "No options found";
                        }}
                      />
                      {skillsRequired ? (
                        <p className="error-message">Skills are required</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <span
                      className="mt-2 ml-2"
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "grey"; // change color on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "black"; // revert back to default color
                      }}
                      onClick={openModal}
                    >
                      Skill not found? Click to Add custom skills!
                    </span>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      style={customStylesPopUp}
                      contentLabel="Example Modal"
                    >
                      <label
                        className="salary-range-recr mb-4"
                        style={{ width: "600px", textAlign: "center" }}
                      >
                        Write a skill and press enter!
                      </label>
                      <div style={{ maxWidth: "600px" }}>
                        <CreatableSelect
                          placeholder="Type something and press enter..."
                          isMulti
                          styles={customStyles}
                          components={components}
                          menuIsOpen={false}
                          inputValue={inputValue}
                          onKeyDown={handleKeyDown}
                          onInputChange={(newValue) => {
                            setInputValue(newValue);
                          }}
                          value={valueNew}
                          onChange={(newValue) => {
                            setValueNew(newValue);
                          }}
                        />
                      </div>

                      <div style={{ textAlign: "center" }} className="mt-4">
                        <button
                          onClick={closeModal}
                          className="btn btn-primary"
                        >
                          Add Custom Skills
                        </button>
                      </div>
                    </Modal>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <label className="salary-range-recr">Experience</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        {...register("experience_level", {
                          required: false,
                        })}
                      >
                        <option value="">Select here</option>
                        {formData?.experience_level?.map((x, i) => (
                          <option value={x.id} key={i}>
                            {x.name !== null && x.name !== undefined
                              ? x.name
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.experience_level?.type === "required" && (
                        <p className="error-message">Experience is required</p>
                      )}
                    </div>
                  </div>
                  <p className="m-0 p-0 mt-2 salary-range-recr">Salary Range</p>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <label className="salary-range-recr">
                        Minimum Salary
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Minimum salary"
                        {...register("salary_min", {
                          required: false,
                        })}
                      />
                      {errors.salary_min?.type === "required" && (
                        <p className="error-message">
                          Minimum Salary is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">
                        Maximum Salary
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Maximum salary"
                        {...register("salary_max", {
                          required: false,
                        })}
                      />
                      {errors.salary_max?.type === "required" && (
                        <p className="error-message">
                          Maximum Salary is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">Salary Type</label>
                      <select
                        className="form-control"
                        {...register("salary_type", {
                          required: false,
                        })}
                      >
                        <option value="">Select salary type</option>

                        {formData?.salary_types?.map((x, i) => (
                          <option value={x.id} key={i}>
                            {x.sal_type_name !== null &&
                            x.sal_type_name !== undefined
                              ? x.sal_type_name
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.salary_type?.type === "required" && (
                        <p className="error-message">Salary Type is required</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">Currency Type</label>
                      <select
                        className="form-control"
                        {...register("curr_type", {
                          required: false,
                        })}
                      >
                        <option value="">Select Currency Type</option>
                        {countries.all.map((currencies) =>
                          currencies.currencies.length ? (
                            <option value={currencies.currencies[0]}>
                              {currencies.currencies[0]}
                            </option>
                          ) : null
                        )}
                      </select>
                      {errors.curr_type?.type === "required" && (
                        <p className="error-message">Currency is required</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">Career Level</label>
                      <select
                        className="form-control"
                        {...register("functional_area", {
                          required: false,
                        })}
                      >
                        <option value="">Select career level</option>
                        {formData?.functional_area?.map((x, i) => (
                          <option value={x.id} key={i}>
                            {x.name !== null && x.name !== undefined
                              ? x.name
                              : ""}
                          </option>
                        ))}
                      </select>
                      {errors.functional_area?.type === "required" && (
                        <p className="error-message">
                          Career Level is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="salary-range-recr">
                        Total no of positions
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Number of postion"
                        {...register("vacancies", {
                          required: true,
                        })}
                      />
                      {errors.vacancies?.type === "required" && (
                        <p className="error-message">Positions is required</p>
                      )}
                    </div>
                  </div>
                  {localStorage.getItem("eco_complaince") == 0 ? (
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <label className="salary-range-recr">Gender</label>
                        <select
                          className="form-control"
                          {...register("gender", {})}
                        >
                          <option value="">Select Here</option>
                          {formData?.genders?.map((x, i) => (
                            <option value={x.id} key={i}>
                              {x.gender !== null && x.gender !== undefined
                                ? x.gender
                                : ""}
                            </option>
                          ))}
                        </select>
                        {errors.gender?.type === "required" && (
                          <p className="error-message">Gender is required</p>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12">
                  <label className="salary-range-recr">
                    Job Description
                    <i
                      className="fas fa-info-circle pl-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="If you want candidate to respond to certain questions, please highlight and list those questions individually in the job description."
                    ></i>
                  </label>
                  {id && !watchAllFields.job_description ? (
                    <Editor
                      onTextChange={(e) =>
                        setValue("job_description", e.htmlValue)
                      }
                      value={watchAllFields.job_description}
                      style={{ resize: "none", minHeight: "180px" }}
                      placeholder="Enter job description"
                    />
                  ) : (
                    <Editor
                      onTextChange={(e) =>
                        setValue("job_description", e.htmlValue)
                      }
                      value={watchAllFields.job_description}
                      style={{ resize: "none", minHeight: "180px" }}
                      placeholder="Enter job description"
                    />
                  )}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 create-job-btn-recr"
                  >
                    {id ? "Update" : "Create"}
                  </button>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  getCreateJobReducer: state.getCreateJobReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCreateJobData: (userId) => dispatch(getCreateJobData(userId)),
  getEditJobData: (userId, id) => dispatch(getEditJobData(userId, id)),
  createJob: (userId, data) => dispatch(createJob(userId, data)),
  editJob: (userId, id, data) => dispatch(editJob(userId, id, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateAJobs);
