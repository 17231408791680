import { countries } from "country-data";

export const getCountriesData = () => {
  return (dispatch) => {
    if (countries.all) {
      dispatch({
        type: "GET_COUNTRIES_DATA",
        countriesData: countries.all,
        loading: false,
      });
    }
  };
};
